<template>
<div class="tgju-widget-content full-padding">
    <div class="tgju-widget light min-h-unset">
        <div class="list-widget  list-row bold list-row-title">
            <div class="list-col l-title dot head-color no-border">
                <span>سود | زیان</span>
            </div>
            <div class="list-col l-item head-color" v-tooltip="'بروزرسانی'">
                <i class="uil uil-sync pointer" @click="makePortfolio()"></i>
            </div>
            <div v-if="!isMobile" class="list-col l-item head-color">
                <i class="uil uil-chart-line"></i>
            </div>
        </div>
        <div class="tgju-widget-content full-padding">
            <slot v-if="widgetLoad">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </slot>
            <slot v-else>
                <table v-if="portfolios.length" class="widget-table widget-table-lg">
                    <thead>
                        <tr>
                            <th class="text-center">عنوان شاخص</th>
                            <th class="text-center">تعداد</th>
                            <th class="text-center">قیمت خرید</th>
                            <th class="text-center hide-480">ارزش فعلی</th>
                            <th class="text-center hide-table-r hide-640">ارزش زمان خرید</th>
                            <th class="text-center hide-table-r hide-640">ارزش فعلی خرید</th>
                            <th class="text-center  hide-table-r hide-640">سود / زیان</th>
                            <th class="text-center  hide-table-r hide-640">زمان اقدام به خرید</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="portfolio in portfolios" :key="portfolio.id">
                            <td class="border-l text-center">
                                {{ portfolio.label }}
                            </td>
                            <td class="border-l text-center">
                                {{ formatPrice(portfolio.amount) }}
                            </td>
                            <td class="border-l text-center">
                                {{ formatPrice(portfolio.latest_trade_price) }}
                            </td>
                            <td class="border-l text-center hide-480">
                                {{ formatPrice(portfolio.current_indicator_price) }}
                            </td>
                            <td class="border-l text-center hide-table-r hide-640">
                                {{ formatPrice(portfolio.buy_price) }}
                            </td>
                            <td class="border-l text-center hide-table-r hide-640">
                                {{ formatPrice(portfolio.current_price) }}
                            </td>
                            <td class="border-l text-center hide-table-r hide-640">
                                <slot v-if="portfolio.trade > 0">
                                    <strong style="color:green">
                                        {{ formatPrice(convertDollarToRial(portfolio)) + ' ▲' }}
                                    </strong>
                                </slot>
                                <slot v-else-if="portfolio.trade == 0">
                                    <strong>
                                        {{ formatPrice(parseFloat(portfolio.trade)) }}
                                    </strong>
                                </slot>
                                <slot v-else>
                                    <strong style="color:red">
                                        {{ formatPrice(convertDollarToRial(portfolio)) + ' ▼' }}
                                    </strong>
                                </slot>
                            </td>
                            <td class="text-center hide-table-r hide-640">
                                {{ toRelative(portfolio.created_at) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <slot v-else>
                    <div class="popup-dash-empty">
                        <i class="uil uil-chart-line" aria-hidden="true"></i>
                        <span>مقداری برای نمایش وجود ندارد</span>
                    </div>
                </slot>
            </slot>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
</style>

<script>
// این کاپوننت اجزای داخلی سود / زیان را در داشبورد اصلی به نمایش به صورت لیستی می گذارد
import moment from "moment-jalaali";
import Centrifuge from "centrifuge";

export default {
    name: 'PortfolioList',
    components: {},
    data: function () {
        return {
            isMobile:false,
            portfolios: [],
            portfolio_api_data: [],
            current_price_of_tether_irr: '',
            prices: [],
            widgetLoad: true,
        }
    },
    mounted() {
        this.makePortfolio();
        // this.socketMarket();
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // این متد برای دریافت اطلاعات سود و زیان از سرور استفاده میشود
        makePortfolio() {
            this.portfolios = [];
            this.portfolio_api_data = [],
                this.prices = [],
                this.widgetLoad = true,

                this.$helpers.makeRequest('GET', '/investment/portfolio/').then(api_response => {
                    this.$helpers.api_alert(api_response);

                    this.portfolio_api_data = api_response.data.response.portfolio;
                    this.current_price_of_tether_irr = api_response.data.response.current_price_of_tether_irr;

                    var assets = this.portfolio_api_data.map(function (value) {
                        return value.asset;
                    });

                    if (assets.length) {
                        let api_data = {
                            market_ids: assets.join(),
                        };

                        this.$helpers.makeRequest('GET', '/watchlist/market', api_data).then(api_response => {
                            this.$helpers.api_alert(api_response);
                            var assets_value = api_response.data.response.detail;

                            this.prices = assets_value.map(function (value) {
                                return { 'itemId': value.item_id, 'amount': value.p, };
                            });

                            this.portfolio_api_data.forEach((item) => {
                                this.portfolios.push({
                                    amount: item.amount,
                                    currency: item.currency,
                                    asset: item.asset,
                                    created_at: item.created_at,
                                    current_indicator_price: this.getPrice(item.asset),
                                    label: item.label,
                                    latest_trade_price: item.latest_trade_price,
                                    buy_price: (item.latest_trade_price * item.amount),
                                    current_price: ((this.getPrice(item.asset) * item.amount)),
                                    trade: ((this.getPrice(item.asset) * item.amount) - (item.latest_trade_price * item.amount)),
                                })
                            });
                            this.widgetLoad = false;
                        });
                    } else {
                        this.widgetLoad = false;
                    }
                });
        },
        // این متد جهت یافت قیمت شاخص در ارایه مورد نظر استفاده میشود
        getPrice(symbol) {
            var amount = 0;
            this.prices.forEach((item) => {
                if (item.itemId == symbol) {
                    amount = item.amount;
                    return false;
                }
            })
            return amount;
        },
        // این متد ارتباط با سوکت را برقرار میکند و سپس داده های موجود در صفحه را آپدیت میکند
        socketMarket() {
            var self = this;
            var centrifuge = new Centrifuge("wss://stream.tgju.org/connection/websocket");

            centrifuge.subscribe("tgju:stream", function (ctx) {
                for (var i in ctx.data) {
                    var item = ctx.data[i].split("|");
                    if (item[0] === 'market') {
                        for (const [index, portfolio] of Object.entries(self.portfolios)) {
                            if (portfolio.asset == item[2]) {
                                self.portfolios[index].current_price = (item[6].replaceAll(",", "") * self.portfolios[index].amount).toFixed(3);
                                self.portfolios[index].trade = ((item[6].replaceAll(",", "") * self.portfolios[index].amount) - (self.portfolios[index].latest_trade_price * self.portfolios[index].amount)).toFixed(3);
                                self.portfolios[index].current_indicator_price = item[6].replaceAll(",", "");
                            }
                        }
                    }
                }
            });
            centrifuge.connect();
        },
        convertDollarToRial(value) {
            if (value.currency == 'usd') {
                return Math.abs(parseFloat((value.trade * this.current_price_of_tether_irr).toFixed(0)));
            }
            return Math.abs(parseFloat(value.trade.toFixed(0)));
        },
    },
}
</script>

<template>
<div :class="'' + ( isMobile ? 'mobile-page-layout' : '')">

    <slot v-if="isMobile">
        <div class="mobile-page-header">
                <div class="mobile-page-header-row">
                    <div class="mobile-page-header-right">
                        <router-link v-if="isMobile" :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                            <i class="header-uil-icon uil uil-angle-right-b"></i>
                        </router-link>
                        <div class="mobile-page-header-icon">
                            <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 512">
                                <path class="fa-primary" d="M279.3 175C271.7 173.9 261.7 170.3 252.9 167.1L248 165.4C235.5 160.1 221.8 167.5 217.4 179.1s2.121 26.2 14.59 30.64l4.655 1.656c8.486 3.061 17.88 6.095 27.39 8.312V232c0 13.25 10.73 24 23.98 24s24-10.75 24-24V221.6c25.27-5.723 42.88-21.85 46.1-45.72c8.688-50.05-38.89-63.66-64.42-70.95L288.4 103.1C262.1 95.64 263.6 92.42 264.3 88.31c1.156-6.766 15.3-10.06 32.21-7.391c4.938 .7813 11.37 2.547 19.65 5.422c12.53 4.281 26.21-2.312 30.52-14.84s-2.309-26.19-14.84-30.53c-7.602-2.627-13.92-4.358-19.82-5.721V24c0-13.25-10.75-24-24-24s-23.98 10.75-23.98 24v10.52C238.8 40.23 221.1 56.25 216.1 80.13C208.4 129.6 256.7 143.8 274.9 149.2l6.498 1.875c31.66 9.062 31.15 11.89 30.34 16.64C310.6 174.5 296.5 177.8 279.3 175z" />
                                <path class="fa-secondary" d="M568.2 336.3c-13.12-17.81-38.14-21.66-55.93-8.469l-119.7 88.17h-120.6c-8.748 0-15.1-7.25-15.1-15.99c0-8.75 7.25-16 15.1-16h78.25c15.1 0 30.75-10.88 33.37-26.62c3.25-20-12.12-37.38-31.62-37.38H191.1c-26.1 0-53.12 9.25-74.12 26.25l-46.5 37.74L15.1 383.1C7.251 383.1 0 391.3 0 400v95.98C0 504.8 7.251 512 15.1 512h346.1c22.03 0 43.92-7.188 61.7-20.27l135.1-99.52C577.5 379.1 581.3 354.1 568.2 336.3z" /> 
                            </svg>
                        </div>
                        <div class="mobile-page-header-text">سبد دارایی</div>  
                    </div>
                    <div class="mobile-page-header-actions">
        
                        <div class="mobile-page-header-menu">
                            <template>
                                <div class="text-center">
                                    <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                            <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                        </div>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(item, index) in menus" :key="index"  @click.prevent="get_modal(item.modal)">
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                                </div>
                            </template>                 
                        </div>
                    </div>
                </div>
        </div>
        
        <div class="mobile-app-tabs">
            <div :class="'mobile-app-tabs-items ' + (selectedCategory == 1 ? 'app-active-tab' : '')" @click="setActiveCategory(1)">
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <h3>نمای کلی</h3> 
                    </div>
                </div>
            </div>
            <div :class="'mobile-app-tabs-items ' + (selectedCategory == 2 ? 'app-active-tab' : '')" @click="setActiveCategory(2)">
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <h3>دارایی‌ها</h3> 
                    </div>
                </div>
            </div>
            <div :class="'mobile-app-tabs-items ' + (selectedCategory == 3 ? 'app-active-tab' : '')" @click="setActiveCategory(3)">
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <h3>سوابق خرید</h3> 
                    </div>
                </div>
            </div>
            <div :class="'mobile-app-tabs-items ' + (selectedCategory == 4 ? 'app-active-tab' : '')" @click="setActiveCategory(4)">
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <h3>سوابق فروش</h3> 
                    </div>
                </div>
            </div>
        </div>

        <div class="widget-tabs-btn flex-btn mobile-btn-row">
            <a href="" class="tgju-btn m-top min-w130 tgju-btn-success" @click.prevent="open_investment_buy_modal"><i class="uil uil-shopping-cart-alt"></i> خرید</a>
            <a href="" class="m-right tgju-btn m-top min-w130 tgju-btn-danger" @click.prevent="open_investment_sell_modal"><i class="uil uil-shopping-cart-alt"></i> فروش</a>
        </div>
    </slot>

    <div v-if="!isMobile" class="dashboard-widget-tabs dashboard-widget-pages">
        <div class="dashboard-widget-tabs-content">
            <h2>
                <!-- <i class="uil uil-chart"></i> -->
                <svg class="rightnav-svg rightnav-svg-page" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 512">
                    <path class="fa-primary" d="M279.3 175C271.7 173.9 261.7 170.3 252.9 167.1L248 165.4C235.5 160.1 221.8 167.5 217.4 179.1s2.121 26.2 14.59 30.64l4.655 1.656c8.486 3.061 17.88 6.095 27.39 8.312V232c0 13.25 10.73 24 23.98 24s24-10.75 24-24V221.6c25.27-5.723 42.88-21.85 46.1-45.72c8.688-50.05-38.89-63.66-64.42-70.95L288.4 103.1C262.1 95.64 263.6 92.42 264.3 88.31c1.156-6.766 15.3-10.06 32.21-7.391c4.938 .7813 11.37 2.547 19.65 5.422c12.53 4.281 26.21-2.312 30.52-14.84s-2.309-26.19-14.84-30.53c-7.602-2.627-13.92-4.358-19.82-5.721V24c0-13.25-10.75-24-24-24s-23.98 10.75-23.98 24v10.52C238.8 40.23 221.1 56.25 216.1 80.13C208.4 129.6 256.7 143.8 274.9 149.2l6.498 1.875c31.66 9.062 31.15 11.89 30.34 16.64C310.6 174.5 296.5 177.8 279.3 175z" />
                    <path class="fa-secondary" d="M568.2 336.3c-13.12-17.81-38.14-21.66-55.93-8.469l-119.7 88.17h-120.6c-8.748 0-15.1-7.25-15.1-15.99c0-8.75 7.25-16 15.1-16h78.25c15.1 0 30.75-10.88 33.37-26.62c3.25-20-12.12-37.38-31.62-37.38H191.1c-26.1 0-53.12 9.25-74.12 26.25l-46.5 37.74L15.1 383.1C7.251 383.1 0 391.3 0 400v95.98C0 504.8 7.251 512 15.1 512h346.1c22.03 0 43.92-7.188 61.7-20.27l135.1-99.52C577.5 379.1 581.3 354.1 568.2 336.3z" /> </svg>
                <span class="page-title-svg">سبد دارایی</span>
            </h2> 
            <!-- <p>
                خلاصه توضیحات کوتاه درباره این بخش از داشبورد شبکه اطلاع رسانی ارز طلا و سکه
            </p> -->
            <div class="dashboard-widget-h-btns">
                <a href="" @click.prevent="open_help_modal()" class="m-left m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
            </div>
        </div>
        <div class="widget-tabs">
            <div class="widget-tabs-nav">
                <div class="widget-select menu-tags db flex-grow-1 m-top show-1200">
                    <v-select2 
                    class="tgju-v-select2-m"
                        v-model="selectedCategory"
                        :reduce="(option) => option.id"
                        :clearable="false"
                        label="label"
                        :options="[
                            { label: 'نمای‌کلی', id: 1 },
                            { label: 'دارایی‌ها', id: 2 },
                            { label: 'سوابق خرید', id: 3 },
                            { label: 'سوابق فروش', id: 4 },
                        ]"
                        @input="setActiveCategory()"
                    />
                </div>
                <router-link :to="{ name: 'Investment' }" class="m-left m-top tgju-btn tgju-btn-light-orange active hide-1200">
                    <i class="uil uil-layer-group"></i> نمای‌کلی
                </router-link>
                <router-link :to="{ name: 'InvestmentInventory' }" class="m-left m-top tgju-btn tgju-btn-light-orange hide-1200">
                    <i class="uil uil-wallet"></i> دارایی‌ها
                </router-link>
                <router-link :to="{ name: 'InvestmentBuyHistory' }" class="m-left m-top tgju-btn tgju-btn-light-orange hide-1200">
                    <i class="uil uil-receipt-alt"></i> سوابق خرید
                </router-link>
                <router-link :to="{ name: 'InvestmentSellHistory' }" class="m-left m-top tgju-btn tgju-btn-light-orange hide-1200">
                    <i class="uil uil-receipt-alt"></i> سوابق فروش
                </router-link>
            </div>
            <div class="widget-tabs-btn flex-btn">
                <a href="" class="tgju-btn m-top min-w130 tgju-btn-success active" @click.prevent="open_investment_buy_modal"><i class="uil uil-shopping-cart-alt"></i> خرید</a>
                <a href="" class="m-right tgju-btn m-top min-w130 tgju-btn-danger" @click.prevent="open_investment_sell_modal"><i class="uil uil-shopping-cart-alt"></i> فروش</a>
            </div>
        </div>
    </div>    
    <div class="list-widget list-dash-items list-widget-structure">
        <div v-if="!isMobile" class="dashboard-widget-cards row tgju-widgets-row widget-border-fix">
            <div class="col-12 col-md-6 col-lg-3 col-xl-3">
                <div class="tgju-widget light dashboard-widget-card border-danger mb-m-2">
                    <div class="dashboard-widget-card-icon">
                        <i class="uil uil-file-alt"></i>
                    </div>
                    <div class="dashboard-widget-card-content">
                        <h2>دارایی کل</h2>
                        <span>{{ formatPrice(statistics.total_trades.toFixed(0)) + ' ریال' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 col-xl-3">
                <div class="tgju-widget light dashboard-widget-card border-warning mb-m-2">
                    <div class="dashboard-widget-card-icon">
                        <i class="uil uil-chart-line"></i>
                    </div>
                    <div class="dashboard-widget-card-content">
                        <h2>سود / زیان در 24 ساعت گذشته</h2>
                        <slot v-if="statistics.totals_portfolio > 0">
                            <span style="color:green">
                                {{ '▲ ' + formatPrice(Math.abs(statistics.totals_portfolio.toFixed(0))) + ' ریال'  }}
                            </span>
                        </slot>
                        <slot v-if="statistics.totals_portfolio < 0">
                            <span style="color:red">
                                {{ '▼ ' + formatPrice(Math.abs(statistics.totals_portfolio.toFixed(0))) + ' ریال'  }}
                            </span>
                        </slot>
                        <slot v-if="statistics.totals_portfolio == 0">
                            <span>
                                {{ '0 ریال' }}
                            </span>
                        </slot>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 col-xl-3">
                <div class="tgju-widget light dashboard-widget-card border-green mb-m-2">
                    <div class="dashboard-widget-card-icon">
                        <i class="uil uil-file-info-alt"></i>
                    </div>
                    <div class="dashboard-widget-card-content">
                        <h2>مقادیر ریالی واریزی</h2>
                        <span>{{ formatPrice(statistics.total_irr_trades) + ' ریال' }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 col-xl-3">
                <div class="tgju-widget light dashboard-widget-card border-primary mb-m-2">
                    <div class="dashboard-widget-card-icon">
                        <i class="uil uil-usd-circle"></i>
                    </div>
                    <div class="dashboard-widget-card-content">
                        <h2>مقادیر ارزی واریزی</h2>
                        <span>{{ formatPrice(statistics.total_usd_trades.toFixed(3)) + ' دلار' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <ul class="row tgju-widgets-row dashboard-widget-list widget-border-fix widget-padding" style="padding-top: 0 !important;">
            <li class="tgju-widgets-block col-12 col-xl-12 mb-m-2">
                <Portfolio :key="refresh"></Portfolio>
            </li>
            <li class="tgju-widgets-block col-12 col-xl-12 mb-m-2">
                <Inventory :key="refresh"></Inventory>
            </li>
            <li class="tgju-widgets-block col-12 col-xl-6 mb-m-2">
                <BuyHistory :key="refresh"></BuyHistory>
            </li>
            <li class="tgju-widgets-block col-12 col-xl-6 mb-m-2">
                <SellHistory :key="refresh"></SellHistory>
            </li>
        </ul>
    </div>
  
</div>
</template>

<style lang="scss" scoped>
    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    .high.dir,
    .low.dir {
        max-height: 25px;
        margin: 10px;
    }
</style>

<script>
import Vue from 'vue';
// این کامپوننت دارای اجزای صفحه اصلی سرمایه گذاری می باشد که چندین کامپوننت دیگر را در خود لود میکند
import moment from "moment-jalaali";
import Centrifuge from "centrifuge";
import Inventory from '@/components/Investment/Inventory.vue'
import BuyHistory from '@/components/Investment/BuyHistory.vue'
import SellHistory from '@/components/Investment/SellHistory.vue'
import Portfolio from '@/components/Investment/Portfolio.vue'
import vSelect2 from 'vue-select';
Vue.component('v-select2', vSelect2);


export default {
    name: 'GeneralComponent',
    components: {
        Inventory,
        BuyHistory,
        SellHistory,
        Portfolio,
    },
    props: ['data'],
    data: function () {
        return {
            isMobile:false,
            selectedCategory: 1,
            market_values: [],
            trade_details: [],
            widgetLoad: false,
            refresh: 0,
            statistics: {
                total_irr_trades: 0,
                total_usd_trades: 0,
                total_trades: 0,
                totals_portfolio: 0,
            },
            menus: [
                { title: 'راهنما', modal: 'help' }
            ],
        }
    },
    computed: {
        current_route() {
            return this.$store.state.currentRoute;
        },
    },
    created() {
        this.widgetLoad = true;
    },
    mounted() {
        this.$watch('widgetLoad', function (newValue, oldValue) {
            this.getMarketValue();
            this.getTradeDetails();
            // this.socketMarket();
        }, { immediate: true });

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }

        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        get_modal(name){
            if(name == 'help'){
                this.open_help_modal();
            }
        },
        setActiveCategory(id) {
            if(id){
                this.selectedCategory = id;
            }
            switch(this.selectedCategory){
                case 1:
                    this.$router.push({name: 'Investment'} );
                    break;
                case 2:
                    this.$router.push({name: 'InvestmentInventory'} );
                    break;
                case 3:
                    this.$router.push({name: 'InvestmentBuyHistory'} );
                    break;
                case 4:
                    this.$router.push({name: 'InvestmentSellHistory'} );
                    break;
            }
        },
        
        // این متد ارتباط با سوکت را برقرار میکند و سپس داده های موجود در صفحه را آپدیت میکند
        socketMarket() {
            var self = this;
            var centrifuge = new Centrifuge("wss://stream.tgju.org/connection/websocket");

            centrifuge.subscribe("tgju:stream", function (ctx) {
                for (var i in ctx.data) {
                    var item = ctx.data[i].split("|");
                    if (item[0] === 'market') {
                        for (const [index, market_item] of Object.entries(self.market_values)) {
                            if (market_item.item_id == item[1]) {
                                self.market_values[index].d = item[10];
                                self.market_values[index].dp = item[11];
                                self.market_values[index].dt = item[12];
                                self.market_values[index].h = item[7];
                                self.market_values[index].l = item[8];
                                self.market_values[index].p = item[6];
                                self.market_values[index].t = item[13];
                            }
                        }
                    }
                }
            });

            centrifuge.connect();
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // این متد وظیفه باز کردن مودال خرید ارز و شاخص و هندل کردن کال بک آن
        open_investment_buy_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_BuyModalInvestment_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'InvestmentBuy.vue',
                config: {
                    title: 'سفارش خرید',
                    icon: 'uil uil-shopping-cart-alt with-icon',
                    smallModal: true,
                    data: {},
                }
            };

            this.$root.$emit('open_modal', modal_data);

            // این متد برای هندل کردن کال بک استفاده میشود
            this.$root.$on(callbackEvent, (response) => {

                this.$swal.fire({
                    icon: "success",
                    title: "خرید با موفقیت انجام شد",
                    showConfirmButton: !1,
                    timer: 2000
                });

                //  رفرش کردن کامپونتت های صفحه
                this.refresh = Math.random();
                this.getTradeDetails();
            });
        },
        // این متد وظیفه باز کردن مودال فروش ارز و شاخص و هندل کردن کال بک آن
        open_investment_sell_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_SellModalInvestment_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'InvestmentSell.vue',
                config: {
                    title: 'سفارش فروش',
                    icon: 'uil uil-shopping-cart-alt with-icon',
                    smallModal: true,
                    data: {},
                }
            };

            this.$root.$emit('open_modal', modal_data);
            // این متد برای هندل کردن کال بک استفاده میشود
            this.$root.$on(callbackEvent, (response) => {
                this.$swal.fire({
                    icon: "success",
                    title: "فروش با موفقیت انجام شد",
                    showConfirmButton: !1,
                    timer: 2000
                });
                // رفرش کردن کامپونتت های صفحه
                this.refresh = Math.random();
                this.getTradeDetails();
            });
        },
        // این متد برای دریافت آخرین قیمت های برخی شاخص ها استفاده میشود
        // وضعیت کنونی غیرفعال
        getMarketValue() {
            let self = this;
            let api_data = {
                market_ids: 'mesghal,gold_740k,geram18,geram24,gerami,sekeb,price_dollar_rl,price_eur',
            };
            self.$helpers.makeRequest('GET', '/watchlist/market', api_data).then(api_response => {
                if (api_response.status == 200) {
                    self.market_values = api_response.data.response.detail;
                }
            });
        },
        // این متد برای دریافت اطلاعات ترید و معاملات کاربر می باشد
        getTradeDetails() {
            this.$helpers.makeRequest('GET', '/investment/trade-details').then(api_response => {
                this.$helpers.api_alert(api_response);
                this.statistics = api_response.data.response;
                this.widgetLoad = false;
            });
        }, // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Investment.vue',
                title: 'سبد دارایی',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
